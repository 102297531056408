import React from "react";
import styles from "./H2TrapeziumBG.module.css"

const H2TrapeziumBG = ({uniqueContainerId = "", children}) => {
    return (
        <div id={uniqueContainerId} className={styles.mainTrapeziumContainer}>
            <img
                src="https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/home-v2-h2-bg.svg"
                className={styles.trapeziumBgImage}
                alt={"trapezium shape"}
            />
            <div className={styles.trapeziumBgClip}>
                {children}
            </div>
        </div>
    );
};

export default H2TrapeziumBG;
