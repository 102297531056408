import H2TrapeziumBG from "../TrapeziumBackground/H2TrapeziumBG";
import {IPageFAQComponentProps} from '../../../lib/types/components';
import {getId} from "../../../lib/utils/constants/PagesConstants";
import CustomImageComponent from "../CustomImage/CustomImageComponent";
import styles from "./PageFAQComponentV2.module.css"
import {multipleClassName} from "../../../lib/utils/helpers/helper";
import parse from 'html-react-parser';

const PageFAQComponentV2 = (props: IPageFAQComponentProps) => {

    const {
        pageFAQData,
        type
    } = props;
    if (type === "SUPERSTAR_FAQ") {
        return pageFAQData && Object.keys(pageFAQData).length ? (
            <div
                className={multipleClassName(
                    "container-fluid",
                    styles.pageFAQParentContainerV2Superstar
                )}
                id="id_faq"
            >
                <div
                    className={multipleClassName("waterMark", styles.waterMark)}
                    style={{ whiteSpace: "nowrap" }}
                >
                    {pageFAQData.waterMark &&pageFAQData.waterMark.toUpperCase()}
                </div>
                <div className="container">
                    <h3
                        className={multipleClassName(
                            "sectionHeading",
                            styles.sectionHeading
                        )}
                    >
                        {pageFAQData.sectionHeading}
                    </h3>
                    <h3
                        className={multipleClassName(
                            "subHeading",
                            styles.subHeading
                        )}
                    >
                        {pageFAQData.heading}
                    </h3>

                    <div
                        className={multipleClassName(
                            "row",
                            styles.faqParentContainer
                        )}
                    >
                        <div className="col">
                            {pageFAQData.faqs.map((faq, i) => {
                                return (
                                    <div
                                        className={styles.faqContainer}
                                        key={"pageFAQData.faqs_" + i}
                                    >
                                        <input
                                            className={styles.input}
                                            type="checkbox"
                                            id={`${pageFAQData.heading}-faq-${
                                                i + 1
                                            }`}
                                        />
                                        <label
                                            className={styles.label}
                                            htmlFor={`${
                                                pageFAQData.heading
                                            }-faq-${i + 1}`}
                                        >
                                            <h3 className={multipleClassName(styles.h3, styles.h3Superstar)}>
                                                <span>{i + 1}</span>
                                                <p className={styles.faqTitle}>
                                                    {faq.title}
                                                </p>
                                            </h3>
                                        </label>
                                        <div className={styles.para}>
                                            {faq.paragraphs.map(
                                                (item, index) => {
                                                    return (
                                                        <p key={index}>
                                                            {item}
                                                        </p>
                                                    );
                                                }
                                            )}
                                            {faq?.points && (
                                                <ul>
                                                    {faq.points.map(
                                                        (item, index) => {
                                                            return (
                                                                <li
                                                                    key={
                                                                        "faq_points_" +
                                                                        index
                                                                    }
                                                                >
                                                                    {item}
                                                                </li>
                                                            );
                                                        }
                                                    )}
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <></>
        );
    }
    return (
        pageFAQData && Object.keys(pageFAQData).length ?
            <H2TrapeziumBG uniqueContainerId={"pageFaq_container"}>
                <div className={multipleClassName("container-fluid", styles.pageFAQParentContainerV2)} id="id_faq">
                    <CustomImageComponent
                        src={"https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/contours_bg.webp"}
                        layout={"fill"}
                        alt={"content image"}
                        objectFit={"cover"}/>
                    <div className='container'>
                        <div className={styles.headingParent}>
                            <h2 className={styles.heading} id={getId(pageFAQData.heading)}>{pageFAQData.heading}</h2>
                        </div>
                        <div className={multipleClassName('row', styles.faqParentContainer)}>
                            <div className='col'>
                                {
                                    pageFAQData.faqs.map((faq, i) => {
                                        return (
                                            <div className={styles.faqContainer} key={"pageFAQData.faqs_" + i}>
                                                <input className={styles.input} type="checkbox"
                                                       id={`${pageFAQData.heading}-faq-${i + 1}`}/>
                                                <label className={styles.label}
                                                       htmlFor={`${pageFAQData.heading}-faq-${i + 1}`}>
                                                    <h3 className={styles.h3}>
                                                        <span>{i + 1}</span>
                                                        <p className={styles.faqTitle}>{faq.title}</p>
                                                    </h3>
                                                </label>
                                                <div className={styles.para}>
                                                    {
                                                        faq.paragraphs.map((item, index) => {
                                                            return (
                                                                <p key={index}> 
                                                                    {parse(item)}
                                                                </p>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        faq?.points && <ul>
                                                            {
                                                                faq.points.map((item, index) => {
                                                                    return (
                                                                        <li key={"faq_points_" + index}>
                                                                            {item}
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </H2TrapeziumBG> : <></>
    )
}

export default PageFAQComponentV2;