"use client"

import { useEffect } from "react";
import { IToastComponentProps } from "../../../lib/types/components";
import {useDownloadContext} from "../../../lib/context/CommonContext";

const ToastComponent = (props: IToastComponentProps) => {

    const { internetError } = props;
    const { isNetworkToastOpen, setIsNetworkToastOpen} = useDownloadContext();

    useEffect(()=>{
        let timeout = setTimeout(()=>{
            setIsNetworkToastOpen(false);
        },1000)
        return ()=>clearTimeout(timeout)
    },[])

    return (
        <div style={{
            position: "fixed",
            width: "100vw",
            zIndex: 99999999,
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
        }}>
            {/* bg="danger" onClose={() => setShow(false)} show={true} delay={2000} autohide */}
            {isNetworkToastOpen && <div style={{backgroundColor:'red',maxWidth:'350px'}}>
                <div className="toast-body">
                    <p style={{color: "white", textAlign: "center", fontSize: "20px", margin: "0"}}>
                       {internetError}
                    </p>
                </div>
            </div>}
        </div>
    )
}


export default ToastComponent;